import useAsync from '@finst/core/src/scripts/hooks/use-async';
import getAllAssets from '../services/asset/get-all-assets';
export default function useAssetsDictionary() {
    return useAsync(async ()=>{
        const assets = await getAllAssets();
        return new Map(assets.map((asset)=>[
                asset.symbol,
                asset
            ]));
    }, []);
}
