// extracted by mini-css-extract-plugin
export var expandedLayout = "r6fcActt";
export var label = "VA1zzduk";
export var layout = "fffZeurH";
export var plToggle = "Os5u9EE_";
export var plToggleIcon = "v_rppQNt";
export var plToggleLabel = "uTqSmc2f";
export var plValue = "gut8W8oa";
export var secondaryValue = "XSah2WUn";
export var secondaryValuesList = "nVMP_IaG";
export var secondaryValuesToggle = "JpFAzqY2";
export var totalValue = "PjmF66N7";