import { useLayoutEffect, useState } from 'preact/compat';
import useWatchlistBroker from '../../../hooks/use-watchlist-broker';
const emptyAssetSymbolsList = [];
export default function useWatchlistAssetSymbols() {
    const watchlistBroker = useWatchlistBroker();
    const [assetSymbols, setAssetSymbols] = useState(emptyAssetSymbolsList);
    useLayoutEffect(()=>{
        var _watchlistBroker;
        return (_watchlistBroker = watchlistBroker) === null || _watchlistBroker === void 0 ? void 0 : _watchlistBroker.on(setAssetSymbols);
    }, [
        watchlistBroker
    ]);
    return {
        isLoading: watchlistBroker !== undefined && assetSymbols === emptyAssetSymbolsList,
        value: assetSymbols
    };
}
