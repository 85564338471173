// extracted by mini-css-extract-plugin
export var activeLayout = "BSmSe4XZ";
export var badge = "m4kjzAUG";
export var buttonsLine = "lhz7ZfJp";
export var content = "Fli7agO9";
export var continueButton = "jY28OsZ6";
export var image = "W42BaFG3";
export var indicator = "reEq9A5A";
export var layout = "N9_DJKoK";
export var navigation = "deYlqSsd";
export var navigationButton = "E3v51HCO";
export var specialDealLayout = "jPJ8XTkx";
export var title = "h1VoOtcb";