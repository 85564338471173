export const tradingPriceFields = [
    'bidPrice',
    'askPrice'
];
export const allAssetCategoryIds = [
    'AI',
    'GAMING',
    'MEME',
    'L1',
    'L2',
    'DEFI',
    'META',
    'INFRA',
    'SMART',
    'GREEN',
    'STABLECOIN',
    'POW',
    'POS',
    'IOT',
    'STORAGE',
    'GOV',
    'NFT'
];
