import isAssetTradable from '../../../../services/asset/is-asset-tradable';
import isAssetUpvotable from '../../../../services/asset/is-asset-upvotable';
export default function checkAssetCategory(asset, assetCategoryId, watchListAssetSymbols) {
    if (assetCategoryId === 'ALL') {
        return true;
    }
    if (assetCategoryId === 'TRADABLE') {
        return isAssetTradable(asset);
    }
    if (assetCategoryId === 'UPVOTE') {
        return isAssetUpvotable(asset);
    }
    if (assetCategoryId === 'WATCHLIST') {
        return watchListAssetSymbols.includes(asset.symbol);
    }
    return asset.categories !== undefined && asset.categories.some((category)=>category.id === assetCategoryId);
}
