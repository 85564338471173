const numberUnits = [
    [
        'T',
        1000000000000
    ],
    [
        'B',
        1000000000
    ],
    [
        'M',
        1000000
    ],
    [
        'K',
        1000
    ]
];
const defaultAbbreviations = [
    'T',
    'B',
    'M',
    'K'
];
/**
 * @example
 * abbreviateNumber(1000) => [1, 'K', 1000]
 * @param {number} value
 * @param {NumberUnitAbbr[]} [abbreviations]
 * @returns {[number, string, number]}
 */ export default function abbreviateNumber(value, abbreviations = defaultAbbreviations) {
    let abbr = '';
    let magnitude = 1;
    const absValue = Math.abs(value);
    const valueSize = numberUnits.find(([abbr, magnitude])=>absValue >= magnitude && abbreviations.includes(abbr));
    if (valueSize) {
        [abbr, magnitude] = valueSize;
        value /= magnitude;
    }
    return [
        value,
        abbr,
        magnitude
    ];
}
