export default function parseSortValues(input, allowedColumns) {
    const entries = input.split(',').map((value)=>{
        const [name, direction] = value.split('.');
        return [
            name,
            direction
        ];
    }).filter((entry)=>(entry[1] === 'ASC' || entry[1] === 'DESC') && allowedColumns.includes(entry[0]));
    return new Map(entries);
}
