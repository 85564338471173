// extracted by mini-css-extract-plugin
export var actionShortcuts = "WVsHSpL9";
export var autoInvestCard = "rrijUsuo";
export var cardsCarousel = "lHOuzgdt";
export var carouselCard = "EJGoxtM3";
export var marketSummary = "i1_3tSXd";
export var section = "iTxg4rSm";
export var sectionFooter = "XL4bE5aU";
export var sectionHeader = "NLHmL_z6";
export var sectionHeaderAction = "XrYXAltP";
export var sectionHeaderTitle = "DhiCOLxO";