const cutoffs = [
    [
        1000 * 60 * 60 * 24 * 364,
        'year'
    ],
    [
        1000 * 60 * 60 * 24 * 30,
        'month'
    ],
    [
        1000 * 60 * 60 * 24,
        'day'
    ],
    [
        1000 * 60 * 60,
        'hour'
    ],
    [
        1000 * 60,
        'minute'
    ],
    [
        1000,
        'second'
    ]
];
export default function formatRelativeDate(date, language, options) {
    var _options;
    const style = ((_options = options) === null || _options === void 0 ? void 0 : _options.style) || 'short';
    const delta = date.getTime() - Date.now();
    const absDelta = Math.abs(delta);
    const cutoff = cutoffs.find(([value])=>absDelta >= value);
    if (!cutoff || !Intl.RelativeTimeFormat) {
        return '';
    }
    const [magnitude, unit] = cutoff;
    const formatter = new Intl.RelativeTimeFormat(language, {
        numeric: 'always',
        style
    });
    const value = Math.round(absDelta / magnitude);
    return formatter.format(delta < 0 ? -value : value, unit);
}
