import request from '@finst/core/src/scripts/services/api/request';
export default async function getNews(params) {
    const { language } = params;
    return request({
        url: '/api/news',
        // TODO: support other languages in the future
        params: {
            ...params,
            language: language === 'pt' || language === 'en' ? language : 'en'
        }
    });
}
