import { useLayoutEffect, useState } from 'preact/compat';
import useAutoInvestBroker from './use-auto-invest-broker';
export default function useScheduledInvestments() {
    const autoInvestBroker = useAutoInvestBroker();
    const [scheduledInvestments, setScheduledInvestments] = useState();
    useLayoutEffect(()=>{
        var _autoInvestBroker;
        return (_autoInvestBroker = autoInvestBroker) === null || _autoInvestBroker === void 0 ? void 0 : _autoInvestBroker.on(setScheduledInvestments);
    }, [
        autoInvestBroker
    ]);
    return {
        isLoading: autoInvestBroker !== undefined && !scheduledInvestments,
        value: scheduledInvestments
    };
}
