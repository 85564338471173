import useAsync from '@finst/core/src/scripts/hooks/use-async';
import canRequestPromotions from '../services/promotion/can-request-promotions';
import getPromotions from '../services/promotion/get-promotions';
import useIdentity from './use-identity';
export default function useExistingTradingCreditPromotion() {
    const { state: identityState } = useIdentity();
    const canRequest = canRequestPromotions(identityState);
    return useAsync(async ()=>{
        if (!canRequest) {
            return;
        }
        const { items } = await getPromotions({
            requireTotal: false,
            pageNumber: 1,
            // We assume that 5 items are enough to find at least one TRADING_CREDIT
            // TODO: add optional categories=[] param to the API
            pageSize: 5,
            statuses: [
                'ACTIVE',
                'EXPIRING_SOON'
            ]
        });
        return items.find(({ promotionCodeCategory })=>promotionCodeCategory === 'TRADING_CREDIT');
    }, [
        canRequest
    ]);
}
