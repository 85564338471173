import { useEffect, useMemo, useState } from 'preact/compat';
import isAssetTradable from '../services/asset/is-asset-tradable';
import useAssetsDictionary from './use-assets-dictionary';
import useQuotecast from './use-quotecast';
const emptySymbolsList = [];
export default function useMarketMovers(groupSize) {
    const quotecast = useQuotecast();
    const [symbols, setSymbols] = useState(emptySymbolsList);
    const { value: assetsDictionary } = useAssetsDictionary();
    useEffect(()=>{
        var _quotecast;
        return (_quotecast = quotecast) === null || _quotecast === void 0 ? void 0 : _quotecast.onMarketMovers(setSymbols);
    }, [
        quotecast
    ]);
    return useMemo(()=>{
        if (!assetsDictionary) {
            return {
                winners: [],
                losers: []
            };
        }
        const movers = symbols.map((symbol)=>assetsDictionary.get(symbol)).filter((asset)=>asset !== undefined && isAssetTradable(asset));
        return {
            winners: movers.slice(0, groupSize),
            losers: movers.slice(groupSize).slice(-groupSize).reverse()
        };
    }, [
        assetsDictionary,
        symbols,
        groupSize
    ]);
}
