export default function createSortValuesComparator(sortValues, mapFn = (item, sortColumn)=>item[sortColumn]) {
    return (a, b)=>{
        for (const [field, sortDirection] of sortValues.entries()){
            const aValue = mapFn(a, field, sortDirection);
            const bValue = mapFn(b, field, sortDirection);
            const directionValue = sortDirection === 'DESC' ? 1 : -1;
            if (aValue == null && bValue != null) {
                return 1;
            }
            if (aValue != null && bValue == null) {
                return -1;
            }
            if (aValue != null && bValue != null && aValue < bValue) {
                return directionValue;
            }
            if (aValue != null && bValue != null && aValue > bValue) {
                return -directionValue;
            }
        }
        return 0;
    };
}
