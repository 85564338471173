import { useLayoutEffect, useMemo, useState } from 'preact/compat';
import useAccountProgressState from '../../../../hooks/use-account-progress-state';
// enable auto-rotation once per page load
let isAutoRotationEnabled = true;
export default function usePromotionAdTypes(existingTradingCreditPromotion) {
    const accountProgressState = useAccountProgressState();
    const types = useMemo(()=>{
        if (accountProgressState === 'NO_ACCOUNT') {
            return [
                'NEW_ACCOUNT'
            ];
        }
        if (accountProgressState === 'NO_BANK_ACCOUNT') {
            return [
                'DEPOSIT'
            ];
        }
        if (accountProgressState === 'UNFINISHED_REGISTRATION') {
            return [
                'UNFINISHED_REGISTRATION'
            ];
        }
        const types = [
            'TRADING_CREDIT'
        ];
        if (accountProgressState === 'NO_TRADE') {
            types.unshift('FIRST_TRADE');
        }
        if (existingTradingCreditPromotion) {
            types.push('ACTIVE_TRADING_CREDIT');
        }
        return types;
    }, [
        accountProgressState,
        existingTradingCreditPromotion
    ]);
    const [selectedType, setSelectedType] = useState(types[0]);
    const typesCount = types.length;
    const selectedTypeIndex = types.indexOf(selectedType);
    const previousType = types[selectedTypeIndex - 1];
    const nextType = types[selectedTypeIndex + 1];
    useLayoutEffect(()=>setSelectedType(types[0]), [
        types
    ]);
    useLayoutEffect(()=>{
        // 1 full rotation is reached
        if (typesCount > 1 && !nextType) {
            isAutoRotationEnabled = false;
            return;
        }
        if (!isAutoRotationEnabled || !nextType) {
            return;
        }
        const timerId = self.setTimeout(()=>setSelectedType(nextType), 10000);
        return ()=>self.clearTimeout(timerId);
    }, [
        typesCount,
        nextType
    ]);
    return {
        selectedType,
        types,
        previousType,
        nextType,
        select: setSelectedType
    };
}
