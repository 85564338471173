import { useEffect, useState } from 'preact/compat';
import useQuotecast from './use-quotecast';
export default function useMarketData() {
    const quotecast = useQuotecast();
    const [marketData, setMarketData] = useState({
        relativeDifference: undefined
    });
    useEffect(()=>{
        var _quotecast;
        return (_quotecast = quotecast) === null || _quotecast === void 0 ? void 0 : _quotecast.onMarketData(setMarketData);
    }, [
        quotecast
    ]);
    return marketData;
}
