export const defaultSortColumns = [
    'isInWatchlist',
    'name',
    'relativeDifference',
    'volume24hTo',
    'marketCap'
];
export const defaultUpvoteSortColumns = [
    'totalUpvotes',
    ...defaultSortColumns
];
export const defaultSortValues = new Map([
    [
        'marketCap',
        'DESC'
    ]
]);
export const defaultUpvoteSortValues = new Map([
    [
        'totalUpvotes',
        'DESC'
    ]
]);
